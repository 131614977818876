import { useState } from 'react';
import { supabase } from '../../supabaseClient';
import { Form, Tab, Row, Col, Card } from 'react-bootstrap';

const Email = ({ value, set }) => {
  const re =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  const isValid = re.test(value);
  const kwaliteitsbeeld = value.endsWith('@kwaliteitsbeeld.nl');
  return (
    <div>
      <Form.Control
        className="rounded-pill px-3"
        placeholder="E-mail"
        type="email"
        value={value}
        isValid={isValid}
        onChange={(e) => set(e.target.value)}
        size="lg"
      />
      {isValid && !kwaliteitsbeeld ? (
        <div className="ms-3">
          <Form.Text>
            Dit lijkt geen @kwaliteitsbeeld.nl e-mailadres te zijn. Weet je
            zeker dat dit klopt?
          </Form.Text>
        </div>
      ) : null}
    </div>
  );
};

export default function Login() {
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState('');

  const signInWithOtp = async (event) => {
    event.preventDefault();

    setLoading(true);
    const { error } = await supabase.auth.signInWithOtp({
      email,
      options: { redirectTo: window.location },
    });

    if (error) {
      console.error(error);
      alert(error.error_description || error.message);
    } else {
      alert('Check je e-mail voor een login link!');
    }
    setLoading(false);
  };

  return (
    <main className="d-flex flex-column align-items-center justify-content-center pb-5 vh-100 vw-100 bg-black">
      <Card
        // className="mb-3 bg-white p-5 border border rounded"
        style={{ width: '650px' }}
      >
        <Card.Header className="px-5 py-4">
          <h1 className="mb-0">Infozoeker Inloggen</h1>
        </Card.Header>
        <Card.Body className="p-5 pt-4">
          <p className="lead ">
            Vul je{' '}
            <span className="fw-bold text-primary">@kwaliteitsbeeld.nl</span>{' '}
            e-mailadres in om een inloglink opgestuurd te krijgen.
          </p>
          <form className="d-flex flex-column gap-3" onSubmit={signInWithOtp}>
            <Email value={email} set={setEmail} />
            <button
              className={'btn btn-primary btn-lg rounded-pill block'}
              onClick={signInWithOtp}
              disabled={loading || email === ''}
            >
              {loading ? <span>Laden</span> : <span>Stuur inloglink</span>}
            </button>
          </form>
        </Card.Body>
      </Card>
    </main>
  );
}
